<template>
  <div class="container mt-4">
    <b-row class="my-3">
      <b-col class="d-flex align-items-center">
        <h5>{{ translations.create_and_details.page_title_create }}</h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button variant="primary" :disabled="saving" @click="save">
          {{ translations.create_and_details.btn_save }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-form-group
          id="input-name"
          :invalid-feedback="handleError('rvpForm.name')"
          :state="!validateRequiredFields || isValid('rvpForm.name')"
          :label="translations.create_and_details.form.name"
          class="flex-1">
          <b-form-input
            id="input-name"
            v-model.trim="rvpForm.name"
            maxlength="254"/>
          </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-salesforce-id"
          :invalid-feedback="handleError('rvpForm.salesforce_id')"
          :state="!validateRequiredFields || isValid('rvpForm.salesforce_id')"
          :label="translations.create_and_details.form.salesforce_id"
          class="flex-1">
          <b-form-input
            id="input-salesforce-id"
            v-model.trim="rvpForm.salesforce_id"
            maxlength="254"/>
          </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-title"
          :invalid-feedback="handleError('rvpForm.title')"
          :state="!validateRequiredFields || isValid('rvpForm.title')"
          :label="translations.create_and_details.form.title"
          class="flex-1">
          <b-form-input
            id="input-title"
            v-model.trim="rvpForm.title"/>
          </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-email"
          :invalid-feedback="handleError('rvpForm.email')"
          :state="!validateRequiredFields || isValid('rvpForm.email')"
          :label="translations.create_and_details.form.email"
          class="flex-1">
          <b-form-input
            id="input-email"
            v-model.trim="rvpForm.email"/>
          </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import service from '@/services/commission';
import translations from '@/translations';

export default {
  name: 'RVPCreate',
  mixins: [ errorHandler ],
  data() {
    return {
      translations: translations.commissions.rvps,
      saving: false,
      rvpForm: {
        name: null,
        salesforce_id: null,
        title: null,
        email: null,
        is_active: true,
      },
      validateRequiredFields: false,
    };
  },
  validations() {
    return this.rules;
  },
  computed: {
    rules() {
      return {
        rvpForm: {
          name: { required },
          salesforce_id: { required },
          title: { required },
          email: { required, email },
        },
      };
    },
  },
  methods: {
    async save() {
      this.validateRequiredFields = true;
      if (this.$v.rvpForm.$invalid) {
        return;
      }

      try {
        this.saving = true;
        const rvp = await service.createRVP(this.rvpForm);
        this.$noty.success(this.translations.success.create);
        this.$router.push(`/commissions/rvps/${rvp.uuid}`);
      } catch (err) {
        let errMessage = err?.response?.data?.error || this.translations.errors.save;
        if (errMessage && errMessage.includes('there is already a RVP with this salesforce ID')) {
          errMessage = this.translations.errors.already_exists;
        }
        this.$noty.error(errMessage);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
